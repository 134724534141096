import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import qs from 'query-string';

import { GlobalConsumer } from 'site/context';
import Redirect from './redirect';

const AuthRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={({ location, ...props }) => (
            <GlobalConsumer>
                {({ isLoggedIn }) =>
                    isLoggedIn ? (
                        <Component location={location} {...props} />
                    ) : (
                        <Redirect
                            to={`/sign-in?${qs.stringify({ target: location.pathname })}`}
                            status={302}
                        />
                    )
                }
            </GlobalConsumer>
        )}
    />
);

AuthRoute.propTypes = {
    component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
};

export default AuthRoute;
