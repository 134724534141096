import { Cloudinary } from 'cloudinary-core';
import cookies from 'js-cookie';
import numeral from 'numeral';
import config from 'config';

const cloudinary = new Cloudinary({ cloud_name: config('/cloudinary/cloudName') });

export function cloudinaryUrl(publicId, options = {}) {
    return cloudinary.url(publicId, options);
}

export function getCookie(name) {
    return cookies.get(name);
}

export function setCookie(name, value) {
    return cookies.set(name, value, { expires: 365 });
}

export function clearCookie(name) {
    cookies.remove(name);
}

export function formatCurrency(
    amount,
    { includeSymbol = true, isCents = true, cents = 'optional' } = {}
) {
    const symbol = includeSymbol ? '$' : '';
    const amountInDollars = isCents ? amount / 100 : amount;
    let centsFormatter = '[.]00';
    if (cents === 'always') centsFormatter = '.00';
    if (cents === 'never') centsFormatter = '';
    return numeral(amountInDollars).format(`${symbol}0,0${centsFormatter}`);
}

export function casualName(firstName = '', lastName = '') {
    let casual = firstName;
    if (lastName) casual += ` ${lastName[0]}.`;
    return casual;
}

export function fullName(firstName = '', lastName = '') {
    return [firstName, lastName].join(' ').trim();
}

export function capitalize(str = '') {
    if (typeof str !== 'string') return '';
    const words = str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1));
    return words.join(' ');
}

const legacyUrls = [
    '/api/',
    '/api-public/',
    '/about-us',
    '/accept-terms',
    '/dashboard',
    '/faqs',
    '/for/',
    '/give/',
    '/choice',
    '/nonprofits',
    '/invitation-token',
    '/manage/campaigns',
    '/manage/recipients',
    '/our-story',
    '/our-team',
    '/playbook',
    '/pricing',
    '/privacy',
    '/product-tour',
    '/sign-out',
    '/staff-picks',
    '/subscription-agreement',
    '/templates',
    '/tos',
    '/unsupported-browser',
    '/welcome-maxpreps',
    '/welcome-tac',
];

export function isLegacyUrl(url = '') {
    // Special case: homepage
    if (url === '/') return true;
    return legacyUrls.some(x => url.startsWith(x));
}
