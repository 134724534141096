import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';

import config from 'config';

export default (options = {}) => {
    const cache = new InMemoryCache();
    const { http = {}, ...clientOptions } = options;

    return new ApolloClient({
        link: ApolloLink.from([
            onError(({ graphQLErrors, networkError }) => {
                if (graphQLErrors) {
                    graphQLErrors.map(({ message, locations, path }) =>
                        console.warn(
                            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
                        )
                    );
                }

                if (networkError) console.warn(`[Network error]: ${networkError}`);
            }),
            new HttpLink({ ...config('/apollo/http'), ...http }),
        ]),
        cache,
        ...clientOptions,
    });
};
