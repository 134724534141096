import Store from 'conf-store';
import camelcase from 'camelcase-keys';
import tailwindConfig from '../tailwind.config';

const env = process.env.NODE_ENV;

const store = new Store({
    env,
    hostname: {
        $filter: 'env',
        production: 'https://pledgeit.org',
        staging: 'https://next-staging.pledgeit.org',
        $default: process.env.HOSTNAME || 'http://localhost:3001',
    },
    sitename: 'PledgeIt.org',
    tagline: 'Every cause needs a champion.',
    description:
        'Online fundraising tools that empower nonprofits and individuals to make an impact',

    server: {
        port: {
            $filter: 'env',
            development: +process.env.WEB_PORT || 3000,
            $default: +process.env.PORT || 80,
        },
    },

    html: {
        stylesheets: [
            {
                href:
                    'https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap',
            },
        ],
        scripts: [],
        title: 'Pledge It',
        favicon: 'https://pledgeit.org/assets/img/favicon/favicon.ico',
    },

    apollo: {
        http: {
            uri: {
                $filter: 'env',
                production: process.env.GRAPHQL_SERVER || 'https://pledgeit.org/graphql',
                staging: process.env.GRAPHQL_SERVER || 'https://next-staging.pledgeit.org/graphql',
                $default: process.env.GRAPHQL_SERVER || 'http://localhost:3000/graphql',
            },
            credentials: 'include',
        },
    },

    cloudinary: {
        cloudName: 'pledge-it',
        quality: 'auto',
        fetchFormat: 'auto',
        secure: 'true',
    },

    facebook: {
        appId: {
            $filter: 'env',
            production: '1480030898990902',
            staging: '1576987322628592',
            $default: '1433023493480016',
        },
    },

    google: {
        apiKey: 'AIzaSyCtZD_Z3_KPVQrKsKpmWAe_t83G3Q1ZeCQ',
        clientId: '228941803611-2ko05kpgd1t3srqq77bpmblakbn0piu5.apps.googleusercontent.com',
        scope: 'profile',

        analytics: [
            {
                trackingId: 'G-8HT3MCQZLY',
                gaOptions: { name: 'default' },
            },
        ],

        autolink: ['touchdownsagainstcancer.com', '3pointchallenge.org'],
    },

    // Expose TailwindCSS config values
    tailwind: camelcase(tailwindConfig, { deep: true }),

    stripe: {
        key: {
            connect: {
                $filter: 'env',
                development: 'pk_test_6n440RRKzIaXZYf6iZpFdodj',
                staging: 'pk_test_6n440RRKzIaXZYf6iZpFdodj',
                production:
                    'pk_live_5160Al7LlZ0uqKbni5PFIORpJ2GfAsp880vWBsAOeynpwq8dCROun6lHdGexiedffXI6u0dwGyVANbafaGkLE25Bg00SnvXGAmt',
            },
            subscriptions: {
                $filter: 'env',
                development: 'pk_test_YVVWUCY9MryxL4o8LkhUdTkt',
                staging: 'pk_test_YVVWUCY9MryxL4o8LkhUdTkt',
                production: 'pk_live_tjRujr9o6pSTWIbnhxXk8BtT',
            },
        },
    },

    sentry: {
        environment: env,
        dsn: {
            $filter: 'env',
            development: '', // We don't want to send errors in development
            testing: '', // ...or testing
            $default: 'https://1245d175a4964b4c859f5752dc133458@sentry.io/1457090',
        },
        debug: {
            $filter: 'env',
            development: true,
            $default: false,
        },
        ignoreErrors: ['NotFoundError', 'Campaign not found'],
    },

    hotjar: {
        id: '1233229',
        version: '6',
    },

    minimum_tip_charge: 50,
    minimum_donation_amount: 100,
    minimum_pledge_amount: 1,
    minimum_total_amount_for_tip: 500,

    // Active experiments and allowed variants
    // TODO: Get these from the model service via graphql
    experiments: {
        tipJarDisplay4: ['A', 'B'],
        paymentProcess: ['A', 'B'],
    },

    blog: {
        sections: [
            {
                slug: 'press',
                label: 'Press',
                title: 'In the News',
                description:
                    'The latest and greatest from the world of Pledge It, including campaign awards, product development, program announcements and more.',
                categories: ['press'],
            },
            {
                slug: 'tips-and-guides',
                label: 'Tips & Guides',
                title: 'Tips & Guides',
                description:
                    'We created these guides and best practices to help you get the most out of your campaign.',
                categories: ['best practice', 'guides'],
            },
            {
                slug: 'champions',
                label: 'Champions',
                title: 'Sample Title',
                description: 'Sample description here',
                categories: ['champion'],
            },
            {
                slug: 'case-studies',
                label: 'Case Studies',
                title: 'Case Studies',
                description:
                    'Select stories from some of our top campaign managers. Read more about their inspiration and advice now.',
                categories: ['case study'],
            },
        ],
    },
});

export default (key, criteria = {}) => store.get(key, { env, ...criteria });
