import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'cloudinary-react';
import config from 'config';
import Container from 'common/container';
import Status from 'routing/status';

const UncaughtError = ({ error, stack }) => {
    const IS_DEVELOPMENT = config('/env') === 'development';

    return (
        <Status code={500}>
            <div className="w-screen h-screen flex flex-col justify-center bg-gray-lightest">
                <Container className="max-w-xxxl text-center overflow-auto py-12">
                    <a href="/">
                        <Image
                            publicId="logo-pledgeit-full.svg"
                            alt="Pledge It"
                            className="block h-24 mx-auto mb-8"
                        />
                    </a>
                    <p className="text-base leading-loose text-gray-base">
                        We&apos;re experiencing some technical difficulties. <br />
                        Please try again later.
                    </p>
                    {IS_DEVELOPMENT && (
                        <div className="my-12">
                            <div className="bg-white rounded-lg shadow-lg p-8 w-md max-w-full mx-auto font-mono text-sm text-left overflow-auto">
                                <pre className="block">
                                    {error.toString()}
                                    {stack.toString('\n')}
                                </pre>
                            </div>
                        </div>
                    )}
                </Container>
            </div>
        </Status>
    );
};

UncaughtError.propTypes = {
    error: PropTypes.shape({
        message: PropTypes.string,
    }).isRequired,
    stack: PropTypes.arrayOf(PropTypes.shape({})),
};

UncaughtError.defaultProps = {
    stack: [],
};

export default UncaughtError;
