import React, { Component, createContext } from 'react';
import PropTypes from 'prop-types';
import root from 'window-or-global';
import ga from 'react-ga4';

import config from 'config';

const GoogleContext = createContext();

export class GoogleProvider extends Component {
    static propTypes = {
        apiKey: PropTypes.string.isRequired,
        clientId: PropTypes.string.isRequired,
        scope: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
            .isRequired,
        children: PropTypes.node,
        analytics: PropTypes.arrayOf(
            PropTypes.shape({
                trackingId: PropTypes.string.isRequired,
                gaOptions: PropTypes.object,
            })
        ).isRequired,
        autolink: PropTypes.arrayOf(PropTypes.string),
    };

    static defaultProps = {
        children: null,
        autolink: null,
    };

    state = {
        hasLoaded: false,
    };

    async componentDidMount() {
        const { apiKey, clientId, scope, analytics, autolink } = this.props;

        // Initialize GA (for pageview tracking)
        ga.initialize(analytics, {
            alwaysSendToDefaultTracker: false,
            debug: config('/env') !== 'production',
        });

        if (autolink) {
            ga.ga('universal.require', 'linker');
            ga.ga('universal.linker:autoLink', autolink);
        }

        // Initialize the API
        if (!global.gapi) await this.loadAPI();
        this.api = global.gapi;

        await this.api.client.init({ apiKey, clientId, scope });
        this.setState({ hasLoaded: true });
    }

    get authInstance() {
        return this.api.auth2.getAuthInstance();
    }

    get isSignedIn() {
        return this.authInstance.isSignedIn.get();
    }

    get currentUser() {
        return this.authInstance.currentUser.get();
    }

    // Loads the API script file & client SDK
    loadAPI = () =>
        new Promise(resolve => {
            const script = root.document.createElement('script');
            script.src = 'https://apis.google.com/js/client.js';
            script.onload = () => root.gapi.load('client', resolve);
            root.document.body.appendChild(script);
        });

    ensureScope = async scope => {
        if (!this.isSignedIn) {
            await this.authInstance.signIn({ scope });
        }

        if (!this.currentUser.hasGrantedScopes(scope)) {
            await this.currentUser.grant({ scope });
        }
    };

    render() {
        const { children } = this.props;
        const { hasLoaded } = this.state;

        return (
            <GoogleContext.Provider
                value={{
                    pageview: path => ga.pageview(path, ['default', 'universal']),
                    ...(hasLoaded
                        ? {
                              api: this.api,
                              client: this.api.client,
                              auth: this.authInstance,
                              isSignedIn: this.isSignedIn,
                              currentUser: this.currentUser,
                              ensureScope: this.ensureScope,
                          }
                        : {}),
                }}
            >
                {children}
            </GoogleContext.Provider>
        );
    }
}

export default GoogleContext;
export const GoogleConsumer = GoogleContext.Consumer;
