import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';

class ErrorBoundary extends Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
        fallback: PropTypes.func,
    };

    static defaultProps = {
        fallback: null,
    };

    state = {
        error: null,
        stack: null,
    };

    componentDidCatch(error, info = {}) {
        // We don't need to log 404's
        if (error.name !== 'NotFoundError') {
            Sentry.withScope(scope => {
                scope.setExtras(info);
                Sentry.captureException(error);
            });
        }

        this.setState(() => ({ error, stack: info.componentStack }));
    }

    render() {
        const { error, stack } = this.state;
        const { children, fallback: Fallback } = this.props;

        return error ? <Fallback error={error} stack={stack} /> : children;
    }
}

export default ErrorBoundary;
