/* eslint-disable dot-notation */
const reduce = require('lodash/reduce');
const defaults = require('tailwindcss/defaultConfig')();

const colors = {
    transparent: 'transparent',

    facebook: '#3D599B',
    twitter: '#0087B2',
    linkedin: '#0074B5',
    stripe: '#28A0E5',
    google: '#DC4A38',
    youtube: '#FF4D3F',
    pinterest: '#D0212A',

    bronze: '#CD793F',
    silver: '#C6C6CD',
    gold: '#FFCA48',
    diamond: '#98CEE2',

    'white-10': 'rgba(255, 255, 255, 0.1)',
    'white-20': 'rgba(255, 255, 255, 0.2)',
    'white-30': 'rgba(255, 255, 255, 0.3)',
    'white-40': 'rgba(255, 255, 255, 0.4)',
    'white-50': 'rgba(255, 255, 255, 0.5)',
    'white-60': 'rgba(255, 255, 255, 0.6)',
    'white-70': 'rgba(255, 255, 255, 0.7)',
    'white-80': 'rgba(255, 255, 255, 0.8)',
    'white-90': 'rgba(255, 255, 255, 0.9)',
    white: '#FFFFFF',

    'gray-10': '#F7F8F8',
    'gray-20': '#E4E6E7',
    'gray-30': '#C5C8C9',
    'gray-40': '#909698',
    'gray-50': '#6D7274',
    'gray-60': '#5D6365',
    'gray-70': '#4C5052',
    'gray-80': '#3A3F41',
    'gray-90': '#232829',

    'black-10': 'rgba(7, 8, 8, 0.1)',
    'black-20': 'rgba(7, 8, 8, 0.2)',
    'black-30': 'rgba(7, 8, 8, 0.3)',
    'black-40': 'rgba(7, 8, 8, 0.4)',
    'black-50': 'rgba(7, 8, 8, 0.5)',
    'black-60': 'rgba(7, 8, 8, 0.6)',
    'black-70': 'rgba(7, 8, 8, 0.7)',
    'black-80': 'rgba(7, 8, 8, 0.8)',
    'black-90': 'rgba(7, 8, 8, 0.9)',
    black: '#070808',

    'green-10': '#EFF4E1',
    'green-20': '#E0EDC0',
    'green-30': '#CFE198',
    'green-40': '#B9D770',
    'green-50': '#9BC13C',
    'green-60': '#84A432',
    'green-70': '#4F6E08',
    'green-80': '#3E5903',
    'green-90': '#263602',

    'blue-10': '#E1EFF4',
    'blue-20': '#BBDEEC',
    'blue-30': '#98CEE2',
    'blue-40': '#6FBAD8',
    'blue-50': '#3C9BC1',
    'blue-60': '#32809F',
    'blue-70': '#04648B',
    'blue-80': '#034C68',
    'blue-90': '#022736',

    'red-10': '#FAEEEB',
    'red-30': '#FCB2A1',
    'red-50': '#EE522F',
    'red-70': '#B72A0B',
    'red-90': '#621704',

    'orange-10': '#FAF6EB',
    'orange-30': '#FCE2A1',
    'orange-50': '#FFBF21',
    'orange-70': '#D49B0C',
    'orange-90': '#996F05',
};

// Aliases
colors['gray-lightest'] = colors['gray-10'];
colors['gray-lighter'] = colors['gray-20'];
colors['gray-light'] = colors['gray-30'];
colors['gray-base'] = colors['gray-50'];
colors['gray'] = colors['gray-50'];
colors['gray-dark'] = colors['gray-70'];
colors['gray-darker'] = colors['gray-80'];
colors['gray-darkest'] = colors['gray-90'];

colors['green-lighter'] = colors['green-20'];
colors['green-light'] = colors['green-40'];
colors['green'] = colors['green-50'];
colors['green-dark'] = colors['green-60'];
colors['green-darker'] = colors['green-70'];

colors['blue-lightest'] = colors['blue-10'];
colors['blue-lighter'] = colors['blue-30'];
colors['blue-light'] = colors['blue-40'];
colors['blue'] = colors['blue-50'];
colors['blue-dark'] = colors['blue-60'];
colors['blue-darker'] = colors['blue-70'];

colors['red-lightest'] = colors['red-10'];
colors['red-light'] = colors['red-50'];
colors['red'] = colors['red-50'];
colors['red-dark'] = colors['red-70'];

colors['orange-light'] = colors['orange-50'];
colors['orange'] = colors['orange-50'];
colors['orange-dark'] = colors['orange-70'];

const textSizes = {
    '0': '0',
    '9': '.9rem',
    '10': '1rem',
    '11': '1.1rem',
    '12': '1.2rem',
    '14': '1.4rem',
    '16': '1.6rem',
    '18': '1.8rem',
    '21': '2.1rem',
    '24': '2.4rem',
    '28': '2.8rem',
    '36': '3.6rem',
    '48': '4.8rem',
};

// Aliases
textSizes['3xs'] = textSizes['9'];
textSizes['2xs'] = textSizes['10'];
textSizes['xs'] = textSizes['12'];
textSizes['sm'] = textSizes['14'];
textSizes['base'] = textSizes['16'];
textSizes['lg'] = textSizes['21'];
textSizes['xl'] = textSizes['28'];
textSizes['2xl'] = textSizes['36'];

const screens = {
    sm: { min: '375px', max: '767px' },
    md: { min: '768px' },
    lg: { min: '960px' },
    xl: { min: '1050px' },
    xxl: { min: '1200px' },
    xxxl: { min: '1440px' },
};

const dimensions = {
    px: '1px',
    0: 0,
    1: '.4rem',
    2: '.6rem',
    3: '.8rem',
    4: '1.2rem',
    5: '1.4rem',
    6: '1.6rem',
    7: '2rem',
    8: '2.4rem',
    9: '2.7rem',
    10: '3rem',
    12: '3.2rem',
    14: '4rem',
    16: '4.8rem',
    18: '5.4rem',
    20: '6rem',
    24: '6.4rem',
    32: '9.6rem',
    48: '12.8rem',
    64: '19.2rem',
    '1/4em': '.25em',
    '1/2em': '.5em',
    '3/4em': '.75em',
    em: '1em',
    '3/2em': '1.5em',
    '2em': '2em',
    '3em': '3em',
};

const percentages = {
    '1/2': '50%',
    '1/3': '33.33333%',
    '2/3': '66.66667%',
    '1/4': '25%',
    '3/4': '75%',
    '1/5': '20%',
    '2/5': '40%',
    '3/5': '60%',
    '4/5': '80%',
    '1/6': '16.66667%',
    '5/6': '83.33333%',
    full: '100%',
};

module.exports = {
    colors,
    screens,

    fonts: {
        sans: ['"Helvetica Neue"', 'Helvetica', 'Arial', 'sans-serif'],
        serif: ['Georgia', '"Times New Roman"', 'Times', 'serif'],
        script: ['WisdomScriptAJ', '"Brush Script MT"', 'cursive'],
        display: [
            '"Barlow Condensed"',
            'Impact',
            '"Arial Narrow"',
            'Helvetica',
            'Arial',
            'sans-serif',
        ],
        number: [
            '"Barlow Condensed"',
            'Impact',
            '"Arial Narrow"',
            'Helvetica',
            'Arial',
            'sans-serif',
        ],
        mono: ['"Lucida Console"', 'Monaco', 'monospace'],
    },

    textSizes,

    fontWeights: {
        light: 300,
        regular: 400,
        medium: 500,
        bold: 700,
        black: 800,
    },

    leading: {
        ...defaults.leading,
        snug: 1.375,
        relaxed: 1.625,
    },

    tracking: defaults.tracking,

    textColors: colors,

    backgroundColors: colors,

    backgroundSize: defaults.backgroundSize,

    borderWidths: {
        ...defaults.borderWidths,
        '3': '3px',
    },

    borderColors: {
        default: colors['gray-30'],
        ...colors,
    },

    borderRadius: {
        none: '0',
        sm: '.3rem',
        default: '.6rem',
        lg: '.8rem',
        full: '9999px',
    },

    width: {
        auto: 'auto',
        ...dimensions,
        ...percentages,
        screen: '100vw',
        ...reduce(
            screens,
            (obj, { min }, name) => ({
                ...obj,
                [name]: min,
            }),
            {}
        ),
    },

    height: {
        auto: 'auto',
        ...dimensions,
        ...percentages,
        screen: '100vh',
    },

    minWidth: {
        ...dimensions,
        ...percentages,
        screen: '100vw',
        ...reduce(
            screens,
            (obj, { min }, name) => ({
                ...obj,
                [name]: min,
            }),
            {}
        ),
    },

    minHeight: {
        ...dimensions,
        ...percentages,
        '1/4-screen': '25vh',
        '1/3-screen': '33vh',
        '1/2-screen': '50vh',
        '2/3-screen': '66vh',
        '3/4-screen': '75vh',
        screen: '100vh',
    },

    maxWidth: {
        ...dimensions,
        ...percentages,
        none: 'none',
        '1/4-screen': '25vw',
        '1/3-screen': '33vw',
        '1/2-screen': '50vw',
        '2/3-screen': '66vw',
        '3/4-screen': '75vw',
        screen: '100vw',
        ...reduce(
            screens,
            (obj, { min }, name) => ({
                ...obj,
                [name]: min,
            }),
            {}
        ),
    },

    maxHeight: {
        ...dimensions,
        ...percentages,
        none: 'none',
        '1/4-screen': '25vh',
        '1/3-screen': '33vh',
        '1/2-screen': '50vh',
        '2/3-screen': '66vh',
        '3/4-screen': '75vh',
        screen: '100vh',
    },

    padding: {
        ...dimensions,
        ...percentages,
    },

    margin: {
        auto: 'auto',
        ...dimensions,
        ...percentages,
    },

    negativeMargin: {
        ...dimensions,
        ...percentages,
    },

    shadows: defaults.shadows,

    zIndex: {
        ...defaults.zIndex,
        sub: -1,
    },

    opacity: {
        '0': '0',
        '5': '.05',
        '10': '.1',
        '15': '.15',
        '20': '.20',
        '25': '.25',
        '30': '.3',
        '35': '.35',
        '40': '.4',
        '45': '.45',
        '50': '.5',
        '55': '.55',
        '60': '.6',
        '65': '.65',
        '70': '.7',
        '75': '.75',
        '80': '.8',
        '85': '.85',
        '90': '.9',
        '95': '.95',
        '100': '1',
    },

    svgFill: defaults.svgFill,

    svgStroke: defaults.svgStroke,

    modules: {
        ...defaults.modules,
        borderRadius: ['responsive', 'hover'],
        display: ['responsive', 'group-hover'],
        margin: ['responsive', 'group-hover'],
        objectFit: [],
        objectPosition: [],
        opacity: ['responsive', 'group-hover', 'hover'],
        pointerEvents: ['responsive', 'group-hover'],
        textColors: ['responsive', 'hover', 'focus', 'group-hover'],
        maxHeight: ['responsive', 'hover'],
    },

    plugins: [
        // Aspect-ratios
        // https://www.npmjs.com/package/tailwindcss-aspect-ratio
        require('tailwindcss-aspect-ratio')({
            ratios: {
                square: [1, 1],
                short: [2, 1],
                tall: [1, 2],
                widescreen: [16, 9],
                card: [5, 3],
            },
            variants: ['responsive'],
        }),

        // Transitions
        // https://www.npmjs.com/package/tailwindcss-transition
        require('tailwindcss-transition')({
            standard: 'all .2s ease',
            transitions: {
                none: 'none',
                slow: 'all .8s ease',
                medium: 'all .4s ease',
                fast: 'all .2s ease',
            },
        }),
    ],

    options: defaults.options,
};
