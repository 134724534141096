import React, { useContext } from 'react';
import GlobalContext from 'site/context';
import posed, { PoseGroup } from 'react-pose';

import FlashAlert from 'common/flash-alert';

const FlashPose = posed.div({
    initial: {
        opacity: 0,
        scale: 0.8,
        translateX: 50,
    },
    enter: {
        opacity: 1,
        scale: 1,
        translateY: 0,
        translateX: 0,
    },
    exit: {
        opacity: 0,
        translateY: -50,
    },
});

const FlashMessages = () => {
    const { removeFlash, flashMessages } = useContext(GlobalContext);

    return (
        <div
            className="fixed pin-t pin-r sm:p-4 p-12 z-50 w-full pointer-events-none"
            css={{ maxWidth: 415 }}
        >
            <PoseGroup withParent={false} preEnterPose="initial">
                {flashMessages.map(flash => (
                    <FlashPose key={flash.id} className="pointer-events-auto">
                        <FlashAlert onDismiss={() => removeFlash(flash.id)} {...flash} />
                    </FlashPose>
                ))}
            </PoseGroup>
        </div>
    );
};

export default FlashMessages;
