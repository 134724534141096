import gql from 'graphql-tag';

export default gql`
    query GetAuthUser {
        my {
            user {
                id
                firstName
                lastName
                email
                username
                avatar {
                    id
                    url
                }
                address {
                    street1
                    street2
                    city
                    stateProv
                    postalCode
                    country
                }
            }
        }
    }
`;
