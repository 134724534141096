import React from 'react';
import { hydrate } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { loadableReady } from '@loadable/component';
import * as Sentry from '@sentry/browser';
import { hotjar } from 'react-hotjar';

import config from 'config';
import createApolloClient from 'data/create-apollo-client';
import App from './app';

// Global Styles
import './style/global.scss';

// Init error tracker
Sentry.init(config('/sentry'));

// Initialize HotJar recordings
if (config('/env') === 'production') {
    hotjar.initialize(config('/hotjar/id'), config('/hotjar/version'));
}

// Create Apollo client
const client = createApolloClient({
    ssrForceFetchDelay: 250,
    mode: 'no-cors',
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'cache-and-network',
        },
        query: {
            fetchPolicy: 'cache-and-network',
        },
    },
});

// Hydrate state
client.cache.restore(window.__STATE__);

// Display SSR errors
const initialContext = {};
if (window.__SSR_ERROR__) {
    initialContext.flashMessages = [
        {
            id: 'ssr-error',
            type: 'warning',
            title: 'SSR Failure',
            message: `Error given: "${window.__SSR_ERROR__}". Check the server logs for more details.`,
            iconStyle: 'regular',
            iconName: 'exclamation-triangle',
        },
    ];
}

// Hydrate app
loadableReady(() => {
    hydrate(
        <BrowserRouter>
            <HelmetProvider>
                <App client={client} initialContext={initialContext} />
            </HelmetProvider>
        </BrowserRouter>,
        document.getElementById('root')
    );
});
