import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StripeProvider } from 'react-stripe-elements';
import root from 'window-or-global';

export default class StripeContext extends Component {
    static propTypes = {
        apiKey: PropTypes.string.isRequired,
        children: PropTypes.node,
    };

    static defaultProps = {
        children: null,
    };

    state = {
        stripe: null,
    };

    async componentDidMount() {
        const { apiKey } = this.props;

        // Initialize the API
        if (!global.Stripe) await this.loadScript();

        this.setState({ stripe: global.Stripe(apiKey) });
    }

    // Loads the API script file & client SDK
    loadScript = () =>
        new Promise(resolve => {
            const script = root.document.createElement('script');
            script.src = 'https://js.stripe.com/v3/';
            script.onload = resolve;
            root.document.body.appendChild(script);
        });

    render() {
        const { children } = this.props;
        const { stripe } = this.state;

        return <StripeProvider stripe={stripe}>{children}</StripeProvider>;
    }
}
