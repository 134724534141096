import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { emitter } from '@marvelapp/react-ab-test';

import config from 'config';

const experimentConfig = config('/experiments');
const tests = Object.keys(experimentConfig).map(x => `${x}: experiment(name: "${x}")`);

const GET_EXPERIMENTS = gql`
    query GetExperiments {
        ${tests.join('\n')}
    }
`;

const Laboratory = ({ children }) => {
    const { loading, data } = useQuery(GET_EXPERIMENTS);

    if (loading) return null;

    for (const [name, variant] of Object.entries(data)) {
        emitter.defineVariants(name, experimentConfig[name]);
        emitter.setActiveVariant(name, variant);
    }

    return children;
};

Laboratory.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Laboratory;
