import React from 'react';
import PropTypes from 'prop-types';
import { Redirect as RouterRedirect } from 'react-router-dom';

import Status from 'routing/status';

const Redirect = ({ from, to, status }) => (
    <Status code={status}>
        <RouterRedirect from={from} to={to} />;
    </Status>
);

Redirect.propTypes = {
    from: PropTypes.string,
    to: PropTypes.string,
    status: PropTypes.number,
};

Redirect.defaultProps = {
    from: null,
    to: null,
    status: 301,
};

export default Redirect;
