import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import cx from 'classnames';

import Markdown from 'common/markdown';
import Icon from 'common/icon';

class FlashAlert extends Component {
    static propTypes = {
        onDismiss: PropTypes.func.isRequired,
        title: PropTypes.string,
        message: PropTypes.string,
        type: PropTypes.oneOf(['info', 'success', 'warning', 'danger']),
        iconName: PropTypes.string,
        iconStyle: PropTypes.oneOf(['regular', 'solid', 'light', 'brands']),
        duration: PropTypes.number,
        className: PropTypes.string,
    };

    static defaultProps = {
        title: null,
        message: null,
        type: 'info',
        iconName: null,
        iconStyle: null,
        duration: null,
        className: '',
    };

    componentDidMount() {
        this.startTimer();
    }

    clearTimer = () => {
        clearTimeout(this.timer);
        this.timer = null;
    };

    startTimer = () => {
        const { duration, onDismiss } = this.props;
        if (!duration) return;
        this.timer = setTimeout(onDismiss, duration);
    };

    resetTimer = () => {
        this.clearTimer();
        this.startTimer();
    };

    render() {
        const { type, title, message, iconName, iconStyle, className, onDismiss } = this.props;

        return (
            <div
                className="rounded border-4 border-black-10 overflow-hidden relative mb-4"
                onMouseEnter={this.clearTimer}
                onMouseLeave={this.startTimer}
            >
                <div
                    className={cx('text-white py-6 px-12 flex items-start', className, {
                        'bg-blue': type === 'info',
                        'bg-green': type === 'success',
                        'bg-orange': type === 'warning',
                        'bg-red': type === 'danger',
                    })}
                >
                    <button
                        type="button"
                        onClick={onDismiss}
                        className="absolute pin-t pin-r mt-4 mr-4 leading-none text-black opacity-20 hover:opacity-40 transition"
                    >
                        <Icon icon="times" />
                    </button>

                    {iconName && (
                        <div className="text-center text-black opacity-20 pr-4 -ml-6 flex-no-shrink">
                            <Icon icon={iconName} type={iconStyle} size="2x" fixedWidth />
                        </div>
                    )}

                    <div className="flex-grow">
                        {title && (
                            <h3 className="text-base font-bold leading-snug w-4/5">{title}</h3>
                        )}
                        {message && (
                            <Markdown
                                className="text-sm leading-snug font-medium my-2"
                                css={css`
                                    a {
                                        color: #fff;
                                        border-bottom: 1px solid;
                                        opacity: 1;

                                        &:hover {
                                            opacity: 0.6;
                                        }
                                    }
                                `}
                            >
                                {message}
                            </Markdown>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default FlashAlert;
