import React from 'react';
import PropTypes from 'prop-types';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Regular
import {
    faBars,
    faTimes,
    faDesktop,
    faTabletAlt,
    faPaperclip,
    faEnvelope,
    faFlag,
    faSpinner,
    faCalendarAlt,
    faCheckCircle,
    faCheckSquare,
    faSquare,
    faTimesCircle,
    faChevronDoubleRight,
    faLink,
    faPencil,
    faExclamationTriangle,
    faArrowAltLeft,
    faChevronCircleDown,
    faGem,
    faUndo,
    faArrowAltCircleRight,
    faUserCircle,
    faCircle,
    faQuestionCircle,
} from '@fortawesome/pro-regular-svg-icons';

// Solid
import {
    faSearch,
    faCheck,
    faCheckSquare as faCheckSquareSolid,
    faChevronCircleRight,
    faChevronLeft,
    faMobileAndroidAlt,
    faBadgeCheck,
    faUsers,
    faCheckCircle as faCheckCircleSolid,
    faTrophy,
    faChevronRight,
    faCaretDown,
    faUserTimes,
    faArrowCircleRight,
    faChevronDown,
    faChevronUp,
} from '@fortawesome/pro-solid-svg-icons';

// Brands
import {
    faFacebook,
    faFacebookF,
    faLinkedinIn,
    faTwitter,
    faGoogle,
    faYoutube,
    faCcAmex,
    faCcDiscover,
    faCcMastercard,
    faCcStripe,
    faCcVisa,
    faChrome,
    faEdge,
    faSafari,
    faFirefox,
} from '@fortawesome/free-brands-svg-icons';

// Compile only the icons that we need
library.add(
    faBars,
    faTimes,
    faDesktop,
    faTabletAlt,
    faPaperclip,
    faEnvelope,
    faFlag,
    faSquare,
    faSpinner,
    faCalendarAlt,
    faChevronDoubleRight,
    faLink,
    faPencil,
    faExclamationTriangle,
    faArrowAltLeft,
    faChevronCircleDown,
    faGem,
    faUndo,
    faCheckSquare,
    faArrowAltCircleRight,
    faUserCircle,
    faCircle,
    faQuestionCircle,
    faChevronDown,
    faChevronUp,

    faSearch,
    faChevronCircleRight,
    faCheckSquareSolid,
    faMobileAndroidAlt,
    faBadgeCheck,
    faCheckCircle,
    faCheck,
    faTimesCircle,
    faUsers,
    faTrophy,
    faChevronLeft,
    faChevronRight,
    faCaretDown,
    faUserTimes,
    faArrowCircleRight,
    faCheckCircleSolid,

    faFacebook,
    faFacebookF,
    faLinkedinIn,
    faTwitter,
    faGoogle,
    faYoutube,
    faCcAmex,
    faCcDiscover,
    faCcMastercard,
    faCcStripe,
    faCcVisa,
    faChrome,
    faEdge,
    faSafari,
    faFirefox
);

const types = {
    regular: 'far',
    solid: 'fas',
    light: 'fal',
    brand: 'fab',
};

/**
 * Pass-through component for FontAwesomeIcon icons offering
 * a separate prop for the icon theme. Will pass along any
 * provided props to the underlying `FontAwesomeIcon` component.
 *
 * For more information read the [FontAwesome documentation]
 * (https://www.npmjs.com/package/@fortawesome/react-fontawesome)
 */
const Icon = ({ icon, type, ...rest }) => (
    <FontAwesomeIcon icon={[types[type], icon]} data-testid="icon" {...rest} />
);

Icon.propTypes = {
    /** FontAwesome icon name */
    icon: PropTypes.string.isRequired,

    /** Which FontAwesome style the icon should come from */
    type: PropTypes.oneOf(['regular', 'solid', 'light', 'brand']),
};

Icon.defaultProps = {
    type: 'regular',
};

export default Icon;
