import React from 'react';
import { Switch, Route } from 'react-router-dom';
import loadable from '@loadable/component';

import AuthRoute from './auth-route';

const IndexPage = loadable(() => import('../pages/index/page'));
const BlogIndexPage = loadable(() => import('../pages/blog/index'));
const BlogCategoryPage = loadable(() => import('../pages/blog/category'));
const BlogPostPage = loadable(() => import('../pages/blog/post'));
const CampaignPage = loadable(() => import('../pages/campaign'));
const FundraiserSupporters = loadable(() => import('../pages/fundraiser-supporters'));
const RegisterPage = loadable(() => import('../pages/register'));
const SignInPage = loadable(() => import('../pages/sign-in'));
const ForgotPasswordPage = loadable(() => import('../pages/forgot-password'));
const SetPasswordPage = loadable(() => import('../pages/set-password'));
const EditProfilePage = loadable(() => import('../pages/manage-account/profile'));
const ManageContributionsPage = loadable(() => import('../pages/manage-contributions'));
const ChangePasswordPage = loadable(() => import('../pages/manage-account/change-password'));
const ContributePage = loadable(() => import('../pages/contribute'));
const NotFoundPage = loadable(() => import('../pages/404'));
const UnsupportedBrowserPage = loadable(() => import('../pages/unsupported-browser'));

const Router = () => (
    <Switch>
        <Route path="/" exact component={IndexPage} />
        <Route path="/unsupported" exact component={UnsupportedBrowserPage} />

        <Route path="/blog" exact component={BlogIndexPage} />
        <Route path="/blog/category/:category" exact component={BlogCategoryPage} />
        <Route path="/blog/post/:slug" exact component={BlogPostPage} />

        <Route path="/register" exact component={RegisterPage} />
        <Route path="/sign-in" exact component={SignInPage} />
        <Route path="/forgot-password" exact component={ForgotPasswordPage} />
        <Route path="/set-password/:token/:user" exact component={SetPasswordPage} />

        <AuthRoute path="/manage/account/profile" exact component={EditProfilePage} />
        <AuthRoute path="/manage/account/password" exact component={ChangePasswordPage} />
        <AuthRoute
            path="/_manage/campaigns/:campaign_id/contributions"
            exact
            component={ManageContributionsPage}
        />

        <Route path="/preview/:id" exact component={CampaignPage} />
        <Route path="/:slug" exact component={CampaignPage} />

        <Route
            path="/:slug/fundraise"
            exact
            render={props => <CampaignPage isFundraisingOnLoad {...props} />}
        />

        <Route path="/:slug/@:fundraiser" exact component={CampaignPage} />
        <Route path="/:slug/@:fundraiser/supporters" exact component={FundraiserSupporters} />

        <Route path="/:slug/contribute" component={ContributePage} exact />
        <Route path="/:slug/contribute/:step" component={ContributePage} exact />
        <Route path="/:slug/pledge" component={ContributePage} exact />
        <Route path="/:slug/donate" component={ContributePage} exact />

        <Route component={NotFoundPage} />
    </Switch>
);

export default Router;
