import React, { createContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import uniqueId from 'lodash/uniqueId';

const GlobalContext = createContext();

export const GlobalProvider = ({
    user,
    signIn,
    signOut,
    register,
    resetPassword,
    updatePassword,
    updateUser,
    initialContext,
    children,
}) => {
    const [scrollLocks, setScrollLocks] = useState(initialContext.scrollLocks || []);
    const [referrers, setReferrers] = useState(initialContext.referrers || {});
    const [flashMessages, setFlash] = useState(initialContext.flashMessages || []);
    const [recentContribution, setRecentContribution] = useState(
        initialContext.recentContribution || null
    );

    // We wrap the state object that we're going to expose in a
    // useMemo call to prevent unnecessary re-renders
    const value = useMemo(
        () => ({
            user,
            isLoggedIn: !!user,
            flashMessages,
            signIn,
            recentContribution,
            register,
            resetPassword,
            signOut,
            updatePassword,
            updateUser,

            removeFlash: id => setFlash(prev => prev.filter(x => x.id !== id)),
            showFlash: flash => setFlash(prev => prev.concat({ id: uniqueId('flash_'), ...flash })),

            removeRecentContribution: () => setRecentContribution({}),
            setRecentContribution: data => setRecentContribution(data),

            shouldLockScroll: !!scrollLocks.length,
            registerScrollLock: id => setScrollLocks(scrollLocks.concat(id)),
            deregisterScrollLock: id => setScrollLocks(scrollLocks.filter(x => x !== id)),

            getLockedContributionReferrer: campaign => referrers[campaign] || null,
            lockContributionReferrer: (campaign, referrer = null) => {
                if (referrers[campaign] === referrer) return;
                setReferrers({ ...referrers, [campaign]: referrer });
            },
        }),
        [scrollLocks, referrers, flashMessages, recentContribution, user]
    );

    return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>;
};

GlobalProvider.propTypes = {
    children: PropTypes.node.isRequired,
    signIn: PropTypes.func.isRequired,
    signOut: PropTypes.func.isRequired,
    register: PropTypes.func.isRequired,
    resetPassword: PropTypes.func.isRequired,
    updatePassword: PropTypes.func.isRequired,
    updateUser: PropTypes.func.isRequired,
    initialContext: PropTypes.shape({
        recentContribution: PropTypes.object,
        scrollLocks: PropTypes.arrayOf(PropTypes.string),
        referrers: PropTypes.arrayOf(PropTypes.object),
        flashMessages: PropTypes.arrayOf(PropTypes.object),
    }),
    user: PropTypes.shape({}),
};

GlobalProvider.defaultProps = {
    initialContext: {},
    user: null,
};

export const GlobalConsumer = GlobalContext.Consumer;
export default GlobalContext;
