import { useContext } from 'react';
import root from 'window-or-global';
import Global from 'site/context';

const ScrollLock = () => {
    const { shouldLockScroll } = useContext(Global);

    if (root.document) {
        const fn = shouldLockScroll ? 'add' : 'remove';
        root.document.querySelector('html').classList[fn]('scroll-lock');
    }

    return null;
};

export default ScrollLock;
