import React from 'react';
import { adopt } from 'react-adopt';
import { Query, Mutation } from '@apollo/react-components';
import get from 'lodash/get';

import GET_AUTH_USER from 'data/queries/get-auth-user';
import SIGN_IN from 'data/queries/sign-in';
import REGISTER from 'data/queries/register';
import RESET_PASSWORD from 'data/queries/reset-password';
import SIGN_OUT from 'data/queries/sign-out';
import UPDATE_PASSWORD from 'data/queries/update-password';
import UPDATE_USER from 'data/queries/update-user';
import { GlobalProvider } from './context';

/* eslint-disable react/prop-types, react/destructuring-assignment */
const Wrapper = adopt(
    {
        authQuery: ({ render }) => <Query query={GET_AUTH_USER}>{render}</Query>,
        signIn: ({ render }) => (
            <Mutation
                mutation={SIGN_IN}
                refetchQueries={[{ query: GET_AUTH_USER }]}
                awaitRefetchQueries
            >
                {render}
            </Mutation>
        ),
        register: ({ render }) => (
            <Mutation
                mutation={REGISTER}
                refetchQueries={[{ query: GET_AUTH_USER }]}
                awaitRefetchQueries
            >
                {render}
            </Mutation>
        ),
        resetPassword: ({ render }) => (
            <Mutation
                mutation={RESET_PASSWORD}
                refetchQueries={[{ query: GET_AUTH_USER }]}
                awaitRefetchQueries
            >
                {render}
            </Mutation>
        ),
        signOut: ({ render }) => (
            <Mutation
                mutation={SIGN_OUT}
                refetchQueries={[{ query: GET_AUTH_USER }]}
                awaitRefetchQueries
            >
                {render}
            </Mutation>
        ),
        updatePassword: ({ render }) => <Mutation mutation={UPDATE_PASSWORD}>{render}</Mutation>,
        updateUser: ({ render }) => (
            <Mutation
                mutation={UPDATE_USER}
                refetchQueries={[{ query: GET_AUTH_USER }]}
                awaitRefetchQueries
            >
                {render}
            </Mutation>
        ),
    },
    ({ authQuery, ...mutations }) => ({
        loading: authQuery.loading,
        user: get(authQuery, 'data.my.user', null),
        ...mutations,
    })
);
/* eslint-enable react/prop-types, react/destructuring-assignment */

export default props => (
    <Wrapper>
        {({
            loading,
            user,
            signIn,
            signOut,
            register,
            resetPassword,
            updatePassword,
            updateUser,
        }) => {
            if (loading) return null;

            return (
                <GlobalProvider
                    user={user}
                    signIn={variables => signIn({ variables })}
                    signOut={signOut}
                    register={variables => register({ variables })}
                    resetPassword={variables => resetPassword({ variables })}
                    updatePassword={variables => updatePassword({ variables })}
                    updateUser={variables => updateUser({ variables })}
                    {...props}
                />
            );
        }}
    </Wrapper>
);
