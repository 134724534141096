import { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import GoogleContext from 'data/services/google';

const PageviewTracker = ({ location }) => {
    const { pageview } = useContext(GoogleContext);
    useEffect(() => pageview(location.pathname), [location.pathname]);
    return null;
};

PageviewTracker.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
};

export default withRouter(PageviewTracker);
