import React from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { FacebookProvider } from 'react-facebook';
import { hot } from 'react-hot-loader';
import { CloudinaryContext } from 'cloudinary-react';
import { config as faConfig, dom } from '@fortawesome/fontawesome-svg-core';
import { Global, css } from '@emotion/core';

import config from 'config';
import { cloudinaryUrl } from 'utils';
import { GoogleProvider } from 'data/services/google';
import StripeProvider from 'data/services/stripe';
import ScrollLock from 'site/scroll-lock';
import Router from 'routing/router';
import PageScroller from 'routing/page-scroller';
import UncaughtError from 'pages/uncaught-error';
import SEO from 'common/seo';
import ErrorBoundary from 'common/error-boundary';
import ApplicationWrapper from 'site/wrapper';
import FlashMessages from 'site/flash-messages';
import PageviewTracker from 'site/pageview-tracker';
import Laboratory from 'site/labratory';

import * as yup from 'yup';
import phone from 'phone';

// Inject the FontAwesome CSS globally to avoid icon flicker
faConfig.autoAddCss = false;

// Add custom phone validator
yup.addMethod(yup.string, 'phone', function validatePhone(message) {
    return this.test({
        name: 'phone',
        exclusive: true,
        message: message || 'Must be a valid phone number',
        test: value => (value ? !!phone(value).length : true),
    });
});

export default hot(module)(({ client, initialContext }) => (
    <ApolloProvider client={client}>
        <CloudinaryContext {...config('/cloudinary')}>
            <GoogleProvider {...config('/google')}>
                <StripeProvider apiKey={config('/stripe/key/connect')}>
                    <FacebookProvider {...config('/facebook')}>
                        <Global styles={css(dom.css())} />
                        <SEO>
                            <title>{config('/tagline')}</title>
                            <meta property="og:type" content="website" />
                            <meta property="og:site_name" content={config('/sitename')} />
                            <meta property="og:title" content={config('/tagline')} />
                            <meta property="og:description" content={config('/description')} />
                            <meta
                                property="og:image"
                                content={cloudinaryUrl('marketing/2021/opengraph', {
                                    transformation: 'facebook',
                                    secure: false,
                                })}
                            />
                            <meta
                                property="og:image:secure_url"
                                content={cloudinaryUrl('marketing/2021/opengraph', {
                                    transformation: 'facebook',
                                    secure: true,
                                })}
                            />
                        </SEO>
                        <ErrorBoundary fallback={UncaughtError}>
                            <Laboratory>
                                <ApplicationWrapper initialContext={initialContext}>
                                    <Router />
                                    <FlashMessages />
                                    <ScrollLock />
                                    <PageScroller />
                                    <PageviewTracker />
                                </ApplicationWrapper>
                            </Laboratory>
                        </ErrorBoundary>
                    </FacebookProvider>
                </StripeProvider>
            </GoogleProvider>
        </CloudinaryContext>
    </ApolloProvider>
));
