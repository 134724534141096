import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import config from 'config';

/**
 * Adds provided meta tags to the document `<head>`.
 * Children tags of same type will overwrite parent tags.
 */
const SEO = ({ children }) => (
    <Helmet defaultTitle={config('/sitename')} titleTemplate={`%s - ${config('/sitename')}`}>
        {children}
    </Helmet>
);

SEO.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

export default SEO;
