import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

/**
 * "Full-width" content container. Provides consistent content
 * width for various site sections. Preserves classnames that
 * are passed-in.
 */
const Container = ({ className, children, ...rest }) => (
    <div
        className={cx('w-full mx-auto px-8 lg:px-24', className)}
        data-testid="container"
        {...rest}
    >
        {children}
    </div>
);

Container.propTypes = {
    /** @ignore */
    className: PropTypes.string,

    /** @ignore */
    children: PropTypes.node,
};

Container.defaultProps = {
    className: '',
    children: null,
};

export default Container;
