/* eslint-disable react/no-danger */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MarkdownIt from 'markdown-it';

/**
 * Converts & renders a Markdown string to HTML. Uses
 * MarkdownIt to perform the conversion.
 *
 * MarkdownIt Documentation: https://github.com/markdown-it/markdown-it
 */
class Markdown extends Component {
    static propTypes = {
        /** @ignore */
        className: PropTypes.string,

        /** Markdown string to convert */
        children: PropTypes.string,

        /** Whether HTML should be parsed in the output */
        allowHTML: PropTypes.bool,

        /** Whether url-like strings should be converted into links */
        autoLink: PropTypes.bool,

        /** Function which will receive the MarkdownIt instance to provide additional configuration. */
        configureRenderer: PropTypes.func,
    };

    static defaultProps = {
        className: null,
        children: '',
        allowHTML: false,
        autoLink: false,
        configureRenderer: null,
    };

    constructor(props) {
        super(props);

        this.renderer = new MarkdownIt({
            html: props.allowHTML,
            xhtmlOut: props.allowHTML,
            linkify: props.autoLink,
        });

        // Allow modifying the configuration from the component call
        if (props.configureRenderer) props.configureRenderer(this.renderer);
    }

    render() {
        const { className, children } = this.props;

        if (!children) return null;

        return (
            <div
                className={className}
                dangerouslySetInnerHTML={{ __html: this.renderer.render(children) }}
                data-testid="markdown"
            />
        );
    }
}

export default Markdown;
